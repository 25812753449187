jQuery('.search-markers').on("click", function(e) {
  setTimeout(function() {
    jQuery('input.facetwp-search').focus();
  }, 300);
});




/*
 *  document ready
 *
 *  This function will render each map when the document is ready (page has loaded)
 *
 *  @type	function
 *  @date	8/11/2013
 *  @since	5.0.0
 *
 *  @param	n/a
 *  @return	n/a
 */
// global var



jQuery(document).ready(function() {

  jQuery(document).on('facetwp-loaded', function() {

    //jQuery('#filters-modal').collapse();
    results_ct = FWP.settings.pager.total_rows;

    // Update results count.
    jQuery('#view-results-trigger').html('View <span>' + results_ct + '</span> results');

    if ('' == FWP.buildQueryString()) {
      jQuery('.reset-button').addClass('disabled');
      jQuery('.reset-button').attr('disabled', 'disabled');
    } else {
      jQuery('.reset-button').removeClass('disabled');
      jQuery('.reset-button').removeAttr('disabled', 'disabled');
    }



    jQuery.each(FWP.settings.num_choices, function(key, val) {
      var $parent = jQuery('.facetwp-facet-' + key).closest('.products-filters-row');
      //(0 === val) ? $parent.hide(): $parent.show();

      if (val === 0) {
        //console.log($parent);
        console.log(key);
        jQuery('h4.' + key + '_header').css('display', 'none');
        jQuery('.facetwp-facet-' + key).css('display', 'none');
      } else {
        jQuery('h4.' + key + '_header').css('display', 'block');
        jQuery('.facetwp-facet-' + key).css('display', 'block');
      }

    });


    // Update the number of filters used.
    var active_filters = 0;

    Object.keys(FWP.facets).forEach(function(key, index) {
      // key: the name of the object key
      // index: the ordinal position of the key within the object
      if (FWP.facets[key].length > 0) active_filters++;

    });

    jQuery('.active-filters .filter-count').html('Active filters: ' + active_filters);
    if (active_filters > 0) jQuery('.active-filters').addClass('has-filters');
    else jQuery('.active-filters').removeClass('has-filters');

    console.log(active_filters);


    // If they're on mobile, send them to the products when the filter.
    if (active_filters > 0 && jQuery(document).width() < 768 && !FWP.is_reset) {

      setTimeout(function() {
        var current_top = getScrollTop();
        var target = jQuery('#facet-products');
        if (target.length) {
          var offset = target.offset().top - 150;
          scrollToOffset(offset);
        }
      }, 50);

    }
    // If they're paging from the bottom, send them to the top (to the top, gotta make it to the toooooooop).
    else {

      setTimeout(function() {
        var current_top = getScrollTop();
        if (current_top > 1500) {
          var target = jQuery('#products-top');
          if (target.length) {
            var offset = target.offset().top - 120;
            scrollToOffset(offset);
          }
        }
      }, 50);
    }


  });



  // Toggle filter stuff.
  jQuery('#product-filters.collapse').on('hide.bs.collapse', function() {
    jQuery('.filter-header span').html('+');
  });

  jQuery('#product-filters.collapse').on('show.bs.collapse	', function() {
    jQuery('.filter-header span').html('-');
  })



  jQuery(document).on('facetwp-refresh', function() {
    jQuery('.facetwp-template').addClass('loading');

  });

  jQuery(document).on('facetwp-loaded', function() {
    jQuery('.facetwp-template').removeClass('loading');
  });

  jQuery('#filters-modal').on('show.bs.collapse', function() {
    jQuery('.filters-overlay').addClass('showing');
  })
  jQuery('#filters-modal').on('shown.bs.collapse', function() {
    jQuery('.filters-overlay').addClass('show');
  })

  jQuery('#filters-modal').on('hide.bs.collapse', function() {
    jQuery('.filters-overlay').removeClass('show');
  })
  jQuery('#filters-modal').on('hidden.bs.collapse', function() {
    jQuery('.filters-overlay').removeClass('showing');
  })

});
